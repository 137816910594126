import axios from "axios";
import store from "../store";
import { ElMessage } from "element-plus";

const baseUrl = process.env.VUE_APP_API_URL;
const api = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
});
api.interceptors.request.use((config) => {
    const token = store.state.token;
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
      console.log("error: ", error);

      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
              // Intentar refrescar el token
              const refreshResponse = await api.post("/auth/refresh");
              const { token } = refreshResponse.data;

              // Guardar el nuevo token en el store
              store.commit("SET_TOKEN", token);

              // Reintentar la solicitud original con el nuevo token
              originalRequest.headers.Authorization = "Bearer " + token;
              return api(originalRequest);
          } catch (refreshError) {
              console.error("Error al refrescar el token: ", refreshError);
              store.dispatch("logout", true);
          }
      }

      // Manejo de otros errores
      if (error.response.status !== 429) {
          ElMessage({
              message: error.response.data.message || "Ocurrió un error",
              type: "error",
              grouping: true,
              appendTo: document.getElementById("#dialogoEdicion") || document.body
          });
      } else {
          ElMessage({
              message: error.response.data,
              type: "error",
              grouping: true
          });
      }

      return Promise.reject(error);
  }
);

export default api;